<template>
  <CInput
    v-bind="{ ...regNumberProps }"
    :value.sync="registrationNumberValue"
    :class="{
      'is-warning': isWarning,
    }"
    @change="validateRegMask"
  >
  </CInput>
</template>
<script>
export default {
  name: 'MRegistrationInput',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: 'Registration Number',
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    geo: {
      type: [Object, String],
      required: false,
      default: null,
    },
    invalidFeedback: {
      type: String,
      default: null,
    },
    isValid: {
      type: Boolean,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    registrationMasks: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    placeholderText: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      registrationNumberState: this.value,

      registrationIsValid: false,
      registrationValidated: false,
      registrationMasksState: this.registrationMasks,
      loadingState: this.loading === true,
      regNumberError: null,
    };
  },
  computed: {
    registrationNumberFeedback() {
      if (this.invalidFeedback) {
        return this.invalidFeedback;
      }

      return this.regNumberError;
    },
    registrationNumberIsValid() {
      if (this.computedIsValid === false || this.computedIsValid === true) {
        return this.computedIsValid;
      }

      if (this.regNumberError) {
        return false;
      }

      return null;
    },
    regNumberProps() {
      var isValid = this.registrationNumberIsValid;
      if (this.registrationNumberFeedback) {
        isValid = false;
      }

      let p = {
        id: this.safeId,
        invalidFeedback: this.registrationNumberFeedback,
        isValid,
        required: this.requiredValue,
        disabled: this.disabled,
      };

      if (this.label && this.label !== '') {
        p.label = this.label;
      }

      if (this.placeholderText && this.placeholderText !== '') {
        p.placeholder = this.placeholderText;
      }

      return p;
    },
    requiredValue() {
      if (this.required === true) {
        return true;
      }

      return false;
    },
    safeId() {
      return this.$attrs.id;
    },
    isLoading: {
      get() {
        return this.loadingState;
      },
      set(v) {
        this.loadingState = v;
        this.$emit('update:loading', v);
      },
    },
    computedIsValid() {
      if (this.registrationValidated && this.registrationIsValid) {
        return true;
      }
      return this.isValid;
    },
    isWarning() {
      if (this.isValid === false) {
        return false;
      }
      return this.registrationValidated && !this.registrationIsValid;
    },
    registrationNumberValue: {
      get() {
        return this.registrationNumberState;
      },
      set(v) {
        const previous = this.registrationNumberState;

        const value = this.$_.trim(v);
        this.registrationNumberState = value;
        this.$emit('update:value', value);
        const current = value;
        this.$emit('change', { current, previous });
      },
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.registrationNumberState = v;
        if (!!v && v.length > 1) {
          this.validateRegMask(v);
        } else {
          this.regNumberError = null;
          this.registrationValidated = false;
          this.registrationIsValid = null;
        }
      },
    },
    registrationMasks: {
      immediate: true,
      handler(v) {
        this.registrationMasksState = v || [];
        if (v && v.length > 0 && this.registrationNumberValue) {
          this.validateRegMask(this.registrationNumberValue);
        } else {
          this.regNumberError = null;
          this.registrationValidated = false;
          this.registrationIsValid = null;
        }
      },
    },
  },
  methods: {
    propId(name) {
      return `${this.safeId}-${name}`;
    },
    validateRegMask(value) {
      const v = this.$_.trim(value);
      let skipRegmask = false;
      if (!v || v === '' || this.registrationMasksState.length < 1) {
        this.registrationValidated = false;
        this.registrationIsValid = false;
        skipRegmask = true;
      }

      let hasError = false;
      if (v && v.length > 0 && v.length <= 255) {
        this.regNumberError = null;
      } else if (v && v !== '') {
        if (v.length === 0 && this.required) {
          this.regNumberError = 'registration is required';
          hasError = true;
        } else if (v.length > 255) {
          this.regNumberError = 'max length is 255 characters';
          hasError = true;
        } else {
          this.regNumberError = null;
        }
      } else if (this.required) {
        this.regNumberError = 'registration is required';
        hasError = true;
      } else {
        this.regNumberError = null;
      }

      if (hasError) {
        this.$emit('invalid:value', this.regNumberError);
        return;
      }

      if (skipRegmask) {
        return;
      }

      let isValid = false;
      for (var regMask of this.registrationMasksState) {
        try {
          let regex = new RegExp(regMask.mask);
          if (regex.test(v)) {
            isValid = true;
            break;
          }
        } catch (err) {
          this.$log.error(`Error parsing regmask '${regMask.mask}'`, err);
        }
      }

      this.registrationValidated = true;
      this.registrationIsValid = isValid;
    },
  },
};
</script>
<style lang="scss">
.input-group-append {
  .input-group-text {
    .form-check-append {
      margin-right: 0.5rem;
      cursor: pointer;
      .c-icon {
        margin-left: 0.5rem;
      }
      &.disabled {
        cursor: default;
      }
    }
    .form-check-append:not(:first-child) {
      border-left: 1px solid #d8dbe0;
      input {
        margin-left: 0.5rem;
      }
    }
    .form-check-append:last-child {
      margin-right: 0;
    }
  }
}

.popover-tooltip {
  h3 {
    padding: 0.25rem 0.5rem 0.5rem 0.5rem;
    margin-bottom: 0;
    border-bottom: 1px solid #ebebeb;
    font-size: 0.8rem;
  }
  div {
    padding: 0.25rem 0.5rem;
    font-size: 0.7rem;
  }
}

html:not([dir='rtl']) {
  .form-control.is-warning {
    background-position: right calc(0.375em + 0.1875rem) center;
    padding-right: calc(1.5em + 0.75rem);
    border-color: #f9b115;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB3aWR0aD0nOCcgaGVpZ2h0PSc4Jz48cmVjdCB3aWR0aD0iMzIiIGhlaWdodD0iMTc2IiB4PSIyNDAiIHk9IjE3NiIgZmlsbD0iI2Y5YjExNTk5Ij48L3JlY3Q+PHJlY3Qgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB4PSIyNDAiIHk9IjM4NCIgZmlsbD0iI2Y5YjExNSI+PC9yZWN0PjxwYXRoIGZpbGw9IiNmOWIxMTUiIGQ9Ik0yNzQuMDE0LDE2SDIzNy45ODZMMTYsNDQ1LjE3NFY0OTZINDk2VjQ0NS4xNzRaTTQ2NCw0NjRINDhWNDUyLjk1OUwyNTYsNTAuODI2LDQ2NCw0NTIuOTU5WiI+PC9wYXRoPjwvc3ZnPg==');
    background-repeat: no-repeat;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  .form-group.is-warning {
    .form-control {
      background-position: right calc(0.375em + 0.1875rem) center;
      padding-right: calc(1.5em + 0.75rem);
      border-color: #f9b115;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiB3aWR0aD0nOCcgaGVpZ2h0PSc4Jz48cmVjdCB3aWR0aD0iMzIiIGhlaWdodD0iMTc2IiB4PSIyNDAiIHk9IjE3NiIgZmlsbD0iI2Y5YjExNTk5Ij48L3JlY3Q+PHJlY3Qgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB4PSIyNDAiIHk9IjM4NCIgZmlsbD0iI2Y5YjExNSI+PC9yZWN0PjxwYXRoIGZpbGw9IiNmOWIxMTUiIGQ9Ik0yNzQuMDE0LDE2SDIzNy45ODZMMTYsNDQ1LjE3NFY0OTZINDk2VjQ0NS4xNzRaTTQ2NCw0NjRINDhWNDUyLjk1OUwyNTYsNTAuODI2LDQ2NCw0NTIuOTU5WiI+PC9wYXRoPjwvc3ZnPg==');
      background-repeat: no-repeat;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }
  }
}
</style>
