<template>
  <CModal :show.sync="modalShow" size="lg" centered :close-on-backdrop="false">
    <template #header>
      <slot name="modal-header">
        <div class="w-100 d-flex">
          <div class="h2">Import Registration Batch</div>
          <div style="margin-left: auto; margin-right: 0">
            <DownloadLink
              class="btn btn-crd btn-edit btn-lg btn-outline-info"
              href="/registrations/template"
              title="Download Template"
            >
              <template #default="{ downloading }">
                <CIcon
                  v-show="!downloading"
                  name="cis-cloud-download"
                  class="mr-2"
                />Template
              </template>
            </DownloadLink>
          </div>
        </div>
      </slot>
    </template>
    <CCardBody v-if="!showErrors">
      <CForm
        :id="propId('frm-import-testdeck')"
        :class="{ 'is-loading': isLoading }"
        @submit.prevent="() => false"
      >
        <CRow>
          <CCol col="6">
            <CInput
              :id="propId('name')"
              label="Name"
              type="text"
              :value.sync="name"
              :invalid-feedback="invalidFeedback('name')"
              :is-valid="isValid('name')"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CTextarea
              :id="propId('description')"
              label="Description"
              placeholder="Description of the contents of the batch file..."
              :value.sync="description"
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <MFileInput
              :id="propId('file')"
              name="file"
              :accept="'.xlsx,.csv'"
              label="Registrations Batch"
              :value="file"
              :invalid-feedback="invalidFeedback('file')"
              :is-valid="isValid('file')"
              @change="fileChanged"
            />
          </CCol>
        </CRow>
      </CForm>
    </CCardBody>
    <CCardBody v-else>
      <h5 class="text-danger">
        <CIcon
          name="cid-exclamation-circle"
          class="mr-2 text-danger"
          width="30"
        />Import Errors Occurred
      </h5>
      <CRow v-show="importErrorMessages.length > 0">
        <CCol>
          <ul>
            <li
              v-for="(message, index) in importErrorMessages"
              :key="`${index}_${message}`"
            >
              {{ message }}
            </li>
          </ul>
        </CCol>
      </CRow>
      <CRow v-if="importRecordErrors.length > 0">
        <CCol>
          <div class="table-responsive">
            <table class="table table-striped table-sm table-import-error">
              <caption>
                The following lines in the file need to be addressed:
              </caption>
              <thead>
                <th scope="col" class="col-10">Line</th>
                <th scope="col" class="col-20">Field</th>
                <th scope="col" class="col-70">Message</th>
              </thead>
              <tbody>
                <tr
                  v-for="(error, index) in importRecordErrors"
                  :key="`${error.fieldName}_${error.objectName}_${index}`"
                >
                  <th scope="row" class="col-10">
                    Line {{ error.lineNumber }}
                  </th>
                  <td class="col-20">{{ error.fieldName }}</td>
                  <td class="col-70">{{ error.message }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCol>
      </CRow>
    </CCardBody>
    <template #footer>
      <CButton color="secondary" :disabled="isLoading" @click="handleCancel"
        >Cancel</CButton
      >
      <CButton
        color="primary"
        :disabled="!canSubmit || hasErrors || isLoading || showErrors"
        @click="handleImport"
        >Import</CButton
      >
      <CButton v-show="showErrors" color="success" @click="doRetryImport"
        >Retry</CButton
      >
    </template>
  </CModal>
</template>
<script>
import { MFileInput } from '@/components/form';
import { formsMixin, mapFormProps } from '@/mixins/forms-mixin';
import api from '@/api';
export default {
  name: 'RegistrationBatchImportModal',
  components: {
    MFileInput,
  },
  mixins: [formsMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  data() {
    return {
      showErrors: false,
      responseErrors: null,
      requiredFields: ['name', 'file'],
      showState: this.show,
      isLoading: false,
      formObject: {
        name: null,
        file: null,
        description: null,
      },
    };
  },
  computed: {
    ...mapFormProps(['name', 'file', 'description'], {
      rootObjectName: 'formObject',
    }),
    importErrorMessages() {
      return this.responseErrors?.messages || [];
    },
    importRecordErrors() {
      return this.responseErrors?.recordErrors || [];
    },
    modalShow: {
      get() {
        return this.showState;
      },
      set(v) {
        this.showState = v;
        this.$emit('update:show', v === true);
      },
    },
  },
  methods: {
    doRetryImport() {
      this.showErrors = false;
      this.responseErrors = null;
      this.clearError('file');
    },
    validateName(input) {
      const self = this;
      if (!input || input.length < 3 || input.length > 255) {
        self.setFieldError('name', 'length must be between 3 and 255');
        return Promise.resolve(true);
      }

      return new Promise((resolve) => {
        if (input?.trim()) {
          self.$nextTick(() => {
            api.tests.decks
              .checkName(input)
              .then((res) => {
                if (res.inUse) {
                  self.setFieldError('name', 'Already in use');
                } else {
                  self.clearError('name');
                }
                resolve(res.inUse);
              })
              .catch((err) => {
                self.$log.error('Error checking registration batch name', err);
                self.setFieldError('name', 'Already in use');
                resolve(false);
              });
          });
        } else {
          self.$nextTick(() => {
            self.clearError('name');
          });
        }
      });
    },
    fileChanged(files) {
      this.showErrors = false;
      this.responseErrors = null;
      if (!files || !files[0]) {
        this.file = null;
        this.clearError('file');
        return;
      }
      let file = files[0];
      this.file = file;
      this.clearError('file');
      const filename = file.name.toLowerCase();
      if (!(filename.endsWith('xlsx') || filename.endsWith('csv'))) {
        let fileType = `${file.type}`.toLowerCase();
        if (
          !(fileType.includes('xlsx') || fileType.includes('csv')) ||
          !fileType
        ) {
          this.setFieldError(
            'file',
            `file type '${this.getFileExtension(
              file.name,
            )}' is not a valid file format`,
          );
        }
      } else {
        if (!this.name) {
          this.name = filename.split('.')[0];
        }
      }
    },
    getFileExtension(filename) {
      return filename.split('.').pop();
    },
    handleCancel() {
      this.modalShow = false;
    },
    handleImport() {
      if (this.isLoading) {
        return;
      }
      const self = this;
      this.validateName(this.name).then((inUse) => {
        if (!inUse) {
          this.isLoading = true;
          this.showErrors = false;
          this.responseErrors = null;
          this.submitForm(() => {
            return api.registrations.importBatch(
              this.name,
              this.file,
              this.description,
            );
          }, true)
            .then(({ response, error }) => {
              if (response && !error) {
                const message = `Successfully imported ${
                  response.recordCount
                } ${this.$plural('entity.registration', response.recordCount)}`;
                this.showSuccess(message).then(() => {
                  this.modalShow = false;
                  this.$emit('imported', response);
                });
              } else if (error && error.response && error.response.data) {
                if (error.response.data.recordErrors) {
                  const messages = error.response.data.messages || [];
                  const recordErrors = (
                    error.response.data.recordErrors || []
                  ).map((o) => Object.freeze(o));

                  self.responseErrors = {
                    messages,
                    recordErrors,
                  };
                  self.showErrors = true;
                } else {
                  self.handleApiError(error);
                }
              }
            })
            .finally(() => {
              this.$nextTick(() => {
                this.isLoading = false;
              });
            });
        }
      });
    },
  },
};
</script>
<style lang="scss">
.table-import-error {
  width: 716px;
  border-spacing: 0;

  caption {
    caption-side: top;
    padding-bottom: 0.25rem;
  }

  thead,
  tbody {
    display: block;

    td,
    th {
      &.col-10 {
        width: 100px;
      }
      &.col-20 {
        width: 150px;
      }
      &.col-70 {
        width: 466px;
      }
    }
  }

  tbody {
    height: 250px;
    overflow-y: auto;
    overflow-x: hidden;

    th,
    td {
      font-size: 75%;
      white-space: nowrap;
      line-height: 1;
    }
    th {
      font-weight: 700;
    }
  }
}
</style>
