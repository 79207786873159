<template>
  <div>
    <EntityDataTableCard
      id="tbl-registration-imports-list"
      title="Registration Validation Batches"
      :loading="loading"
      :items="entities"
      :fields="fields"
      :per-page="25"
      :table-filter="false"
      export-disabled
      import-disabled
      @create="handleImport"
    >
      <template #custom-header-action>
        <CButton
          id="btn-batch-result-refresh"
          class="btn-crd btn-add"
          variant="ghost"
          color="info"
          :title="
            autoRefreshing
              ? 'Auto-Refreshing'
              : loading
              ? 'Refreshing'
              : 'Refresh'
          "
          :disabled="loading"
          @click="fetchData()"
        >
          <CIcon
            name="cil-reload"
            :class="{ spinning: autoRefreshing || loading }"
          />
        </CButton>
      </template>
      <template #name="{ item }">
        <router-link
          :id="`lnk-registration-results-name_${item.id}`"
          :to="{
            name: 'RegistrationValidationBatchDetails',
            params: { batchId: item.id },
          }"
          >{{ item.name }}</router-link
        >
      </template>
      <template #filename="{ item }">
        <router-link
          :id="`lnk-registration-results-filename_${item.id}`"
          :to="{
            name: 'RegistrationValidationBatchDetails',
            params: { batchId: item.id },
          }"
          >{{ item.filename }}</router-link
        >
      </template>
      <template #custom-actions="{ item }">
        <MDownloadButton
          :id="`btn-download-list_${item.id}`"
          :href="`/registrations/results/${item.id}/download`"
        />

        <MDeleteButton
          :id="`btn-delete-list_${item.id}`"
          class="ml-1"
          variant="outline"
          size="sm"
          @click="handleDelete(item)"
        />
      </template>
    </EntityDataTableCard>
    <RegistrationBatchImportModal
      v-if="showImport"
      id="mod-import-reg-batch"
      :show.sync="showImport"
      @imported="() => fetchData()"
    />
  </div>
</template>
<script>
import RegistrationBatchImportModal from '../_modals/RegistrationBatchImportModal';
import EntityDataTableCard from '@/components/EntityDataTableCard';
import MDownloadButton from '@/components/Buttons/MDownloadButton';
import api from '@/api';

const TABLE_FIELDS = [
  {
    key: 'name',
    label: 'Name',
    sorter: true,
    filter: false,
  },
  {
    key: 'filename',
    label: 'Filename',
    sorter: true,
    filter: false,
  },
  {
    key: 'statusLabel',
    label: 'Status',
    sorter: true,
    filter: false,
  },
  {
    key: 'itemCount',
    label: 'Count',
    sorter: false,
    filter: false,
    fieldType: 'NUMBER',
  },
  {
    key: 'processedCount',
    label: 'Processed',
    sorter: false,
    filter: false,
    fieldType: 'NUMBER',
  },
  {
    key: 'findingsCount',
    label: 'Issues',
    sorter: false,
    filter: false,
    fieldType: 'NUMBER',
  },
  {
    key: 'createdAt',
    label: 'Timestamp',
    sorter: true,
    filter: false,
    fieldType: 'DATE',
    format: 'yyyy-MM-dd HH:mm',
  },
  {
    key: 'custom-actions',
    label: '',
    sorter: false,
    filter: false,
    _classes: ['action-cell'],
    toggleable: false,
  },
];
export default {
  name: 'RegistrationValidationImportsTable',
  components: {
    EntityDataTableCard,
    RegistrationBatchImportModal,
    MDownloadButton,
  },
  data() {
    return {
      autoRefreshing: false,
      loading: false,
      entities: [],
      tableFilterValue: null,
      showImport: false,
    };
  },
  computed: {
    fields() {
      return Object.freeze(TABLE_FIELDS);
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleImport() {
      this.showImport = true;
    },
    refresh() {
      this.fetchData();
    },
    fetchData() {
      const self = this;
      this.loading = true;
      api.registrations
        .getAllImportedBatches()
        .then((data) => {
          let setAutoRefresh = false;
          self.entities = (data || []).map((o) => {
            if (o.status === 'PENDING' || o.status === 'IN_PROGRESS') {
              setAutoRefresh = true;
            }
            return Object.freeze({
              ...o,
              statusLabel: self.$t(`regvalStatus.${o.status}`),
            });
          });

          if (setAutoRefresh) {
            self.autoRefreshing = true;
            setTimeout(() => {
              self.refresh();
            }, 5000);
          } else {
            self.autoRefreshing = false;
          }
        })
        .finally(() => {
          self.$nextTick(() => {
            self.loading = false;
          });
        });
    },
    handleDelete(item) {
      const self = this;
      this.$swal
        .fire({
          title: 'Confirm Delete',
          text: 'Are you sure you wish to delete this validation batch?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Yes',
        })
        .then((res) => {
          if (res.isConfirmed) {
            api.registrations
              .deleteResults(item.id)
              .catch((err) => {
                self.$log.error(err);
                self.$swal.fire({
                  title: 'Error',
                  text: 'The batch could not be deleted.',
                  icon: 'error',
                });
              })
              .then(() => {
                self.refresh();
              });
          }
        });
    },
  },
};
</script>
