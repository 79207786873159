<template>
  <div class="dashboard-container">
    <CJumbotron color="white">
      <CRow>
        <CCol>
          <CImg :src="$brand('modios-rapid-verify.png', '/img')" width="280" />
        </CCol>
      </CRow>
    </CJumbotron>
    <CRow>
      <CCol>
        <RegistrationQuickCheck />
      </CCol>
    </CRow>
    <CRow>
      <CCol>
        <RegistrationValidationImportsTable />
      </CCol>
    </CRow>
  </div>
</template>
<script>
import RegistrationValidationImportsTable from './_components/RegistrationValidationImportsTable';
import RegistrationQuickCheck from './_components/RegistrationQuickCheck';
export default {
  name: 'RegistrationValidationDashboard',
  components: {
    RegistrationValidationImportsTable,
    RegistrationQuickCheck,
  },
  data() {
    return {
      showImportsTable: true,
      showQuickCheck: false,
    };
  },
};
</script>
