import { render, staticRenderFns } from "./RegistrationMaskData.vue?vue&type=template&id=03a17a1e"
import script from "./RegistrationMaskData.vue?vue&type=script&lang=js"
export * from "./RegistrationMaskData.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports