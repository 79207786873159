<template>
  <CCard>
    <CCardHeader class="d-flex">
      <div class="h3 card-title">Quick Check</div>
      <div style="margin-left: auto; margin-right: 0">
        <CToggler
          in-header
          @click.prevent="
            () => {
              showBody = !showBody;
            }
          "
        >
          <CIcon :name="showBody ? 'cis-chevron-bottom' : 'cis-chevron-left'" />
        </CToggler>
      </div>
    </CCardHeader>
    <CCollapse :show.sync="showBody">
      <CCardBody>
        <CForm
          id="frm-regval-quick"
          :class="{ 'is-loading': formLoading }"
          @submit.prevent="() => false"
        >
          <CRow>
            <CCol col="12" sm="6">
              <CRow>
                <CCol>
                  <MCountryRegistrationPicker
                    :value.sync="countryGeoKey"
                    key-only
                    @update:value="handleCountryChange"
                  />
                </CCol>
                <CCol>
                  <MDivisionRegistrationPicker
                    v-if="hasCountryDivisions"
                    :country="countryGeoKey"
                    :value.sync="divisionGeoKey"
                    :options="countryDivisions"
                    key-only
                    :disabled="!countryGeoKey || !hasCountryDivisions"
                    @update:value="handleMainDivisionChange"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol>
                  <MRegistrationInput
                    :id="propId('registrationNumber')"
                    name="registrationNumber"
                    :value.sync="registrationNumber"
                    :invalid-feedback="invalidFeedback('registrationNumber')"
                    :is-valid="isValid('registrationNumber')"
                    :geo="geoKey"
                    no-tooltip
                    hide-physical-presence
                    physical-presence-disabled
                    :loading="formLoading"
                    :fetch="false"
                    :required="false"
                    :registration-masks="loadedFormats"
                    @invalid:value="
                      (msg) => setFieldError('registrationNumber', msg)
                    "
                  />
                </CCol>
              </CRow>
              <CRow v-show="geoSupportsExtendedValidation">
                <CCol>
                  <CInput
                    :id="propId('name')"
                    label="Taxpayer Name"
                    name="name"
                    type="text"
                    :value.sync="name"
                    :invalid-feedback="invalidFeedback('name')"
                    :is-valid="isValid('name')"
                    description="Optional, this will be checked against the authority data for the provided registration number."
                  />
                </CCol>
              </CRow>
              <CRow v-show="geoSupportsExtendedValidation">
                <CCol>
                  <CButton
                    color="success"
                    block
                    :disabled="!canSubmit"
                    @click="submitExtendedValidation"
                    >Verify</CButton
                  >
                </CCol>
              </CRow>
            </CCol>
            <CCol col="12" sm="6">
              <CRow>
                <CCol>
                  <RegistrationMaskData
                    :title="samplesTitle"
                    :has-loaded="hasLoadedFormats"
                    :registration-masks="loadedFormats"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CForm>
      </CCardBody>
    </CCollapse>
  </CCard>
</template>
<script>
import api from '@/api';
import { mapGetters } from 'vuex';
import { formsMixin, mapFormProps } from '@/mixins/forms-mixin';
import RegistrationMaskData from './RegistrationMaskData';
import MRegistrationInput from './MRegistrationInput';

import {
  MCountryRegistrationPicker,
  MDivisionRegistrationPicker,
} from '@/components/form/MGeoPicker';
export default {
  name: 'RegistrationQuickCheck',
  components: {
    MCountryRegistrationPicker,
    RegistrationMaskData,
    MRegistrationInput,
    MDivisionRegistrationPicker,
  },
  mixins: [formsMixin],
  data() {
    return {
      showBody: false,
      formLoading: false,
      requiredFields: ['countryGeoKey', 'registrationNumber'],
      loadedCountryFormats: [],
      loadedDivisionFormats: [],
      countryDivisions: [],
      formObject: {
        name: null,
        countryGeoKey: null,
        divisionGeoKey: null,
        registrationNumber: null,
      },
    };
  },
  computed: {
    ...mapFormProps(
      ['name', 'registrationNumber', 'countryGeoKey', 'divisionGeoKey'],
      {
        rootObjectName: 'formObject',
      },
    ),
    ...mapGetters('registrations', ['extendedCountries']),
    extendedCountriesByKey() {
      return this.extendedCountries.reduce((acc, geo) => {
        acc[geo.key] = geo;
        return acc;
      }, {});
    },
    geoSupportsExtendedValidation() {
      if (!this.geoKey || !this.extendedCountriesByKey) {
        return false;
      } else {
        return !!this.extendedCountriesByKey[this.geoKey];
      }
    },
    hasCountryDivisions() {
      return this.countryDivisions.length > 0;
    },
    loadedFormats() {
      if (!this.divisionGeoKey) {
        return this.loadedCountryFormats || [];
      } else {
        return this.loadedDivisionFormats || [];
      }
    },
    hasLoadedFormats() {
      return this.loadedFormats?.length > 0;
    },
    geoKey() {
      if (this.divisionGeoKey) {
        return this.divisionGeoKey;
      } else {
        return this.countryGeoKey;
      }
    },
    samplesTitle() {
      return `Registration Number Samples: ${this.geoKey}`;
    },
  },
  mounted() {
    this.$store.dispatch('registrations/getOrFetchExtendedCountries');
  },
  methods: {
    handleCountryChange(value) {
      this.$nextTick(() => {
        this.divisionGeoKey = null;
        this.loadedDivisionFormats = [];
      });

      if (value) {
        const self = this;
        api.registrations
          .getRegistrationFormatsForGeoKey(value)
          .then((data) => {
            self.loadedCountryFormats = (data || []).map((o) => {
              return Object.freeze(o);
            });
          })
          .catch(() => {
            self.loadedCountryFormats = [];
          });
        self.fetchDivisions(value);
      } else {
        this.loadedCountryFormats = [];
        this.countryDivisions = [];
      }
    },
    handleMainDivisionChange(value) {
      if (value) {
        const self = this;
        api.registrations
          .getRegistrationFormatsForGeoKey(value)
          .then((data) => {
            self.loadedDivisionFormats = (data || []).map((o) => {
              return Object.freeze(o);
            });
          })
          .catch(() => {
            self.loadedDivisionFormats = [];
          });
      } else {
        this.loadedDivisionFormats = [];
      }
    },
    fetchDivisions(countryKey) {
      const self = this;
      return api.registrations.getSupportedDivisions(countryKey).then((res) => {
        self.countryDivisions = (res || []).map((o) => {
          return Object.freeze(o);
        });
      });
    },
    submitExtendedValidation() {
      if (this.formLoading || !this.geoSupportsExtendedValidation) {
        return;
      }

      const self = this;
      const request = {
        countryCode: this.geoKey,
        registrationNumber: this.registrationNumber,
        name: this.name,
      };
      this.formLoading = true;
      api.registrations
        .validateRegistration(request)
        .then((res) => {
          if (!res.isValid) {
            let title = 'Invalid';
            let text =
              res.message || 'The provided registration number is invalid';
            switch (res.status) {
              case 'INVALID':
                title = 'Invalid Format';
                text = 'The format provided does not match any known formats';
                break;
              case 'NOT_FOUND':
                title = 'Invalid Registration';
                text = 'The registration number cannot be found';
                break;
              case 'NAME_MISMATCH':
                title = 'Name Mismatch';
                if (res.name) {
                  text = `The provided name does not match the name: '${res.name}'`;
                } else {
                  text =
                    'The provided name does not match the name found for the registration number';
                }

                break;
              case 'ERROR':
                title = 'Error';
                text =
                  'Registration number validation cannot be performed at this time.';
                break;
              default:
                break;
            }
            return self.$swal.fire({
              icon: 'error',
              title,
              text,
              showCancelButton: false,
            });
          } else {
            return self.$swal.fire({
              icon: 'success',
              title: 'Valid',
              text: 'The provided registration number is valid',
              showCancelButton: false,
            });
          }
        })
        .finally(() => {
          self.$nextTick(() => {
            self.formLoading = false;
          });
        });
    },
  },
};
</script>
