<template>
  <transition name="fade">
    <div
      v-if="hasLoaded && registrationMasks.length > 0"
      class="pr-4 pl-4 pt-0 pb-2"
      style="animation-duration: 0.75s"
    >
      <label class="reg-sample-list-label" :for="`${safeId}_sample-list`"
        ><CIcon class="text-info mr-1" name="cil-info-circle" width="15" />
        {{ title }}
      </label>

      <dl :id="`${safeId}_sample-list`" class="reg-sample-list">
        <dt class="dt-header">Sample</dt>
        <dd class="dd-header">Description</dd>

        <template v-for="(regMask, index) in registrationMasks">
          <dt
            :key="`dt-${index}`"
            :aria-describedby="`dl-regmask-description_${index}`"
          >
            {{ regMask.sample }}
          </dt>
          <dd :id="`dl-regmask-description_${index}`" :key="`dd-${index}`">
            {{ regMask.description }}
          </dd>
        </template>
      </dl>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'RegistrationMaskData',
  props: {
    registrationMasks: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hasLoaded: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: false,
      default: 'Registration Number Samples',
    },
  },
  computed: {
    safeId() {
      return this.$attrs.id || 'regval_sample';
    },
  },
};
</script>
