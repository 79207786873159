var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('EntityDataTableCard',{attrs:{"id":"tbl-registration-imports-list","title":"Registration Validation Batches","loading":_vm.loading,"items":_vm.entities,"fields":_vm.fields,"per-page":25,"table-filter":false,"export-disabled":"","import-disabled":""},on:{"create":_vm.handleImport},scopedSlots:_vm._u([{key:"custom-header-action",fn:function(){return [_c('CButton',{staticClass:"btn-crd btn-add",attrs:{"id":"btn-batch-result-refresh","variant":"ghost","color":"info","title":_vm.autoRefreshing
            ? 'Auto-Refreshing'
            : _vm.loading
            ? 'Refreshing'
            : 'Refresh',"disabled":_vm.loading},on:{"click":function($event){return _vm.fetchData()}}},[_c('CIcon',{class:{ spinning: _vm.autoRefreshing || _vm.loading },attrs:{"name":"cil-reload"}})],1)]},proxy:true},{key:"name",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"id":("lnk-registration-results-name_" + (item.id)),"to":{
          name: 'RegistrationValidationBatchDetails',
          params: { batchId: item.id },
        }}},[_vm._v(_vm._s(item.name))])]}},{key:"filename",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"id":("lnk-registration-results-filename_" + (item.id)),"to":{
          name: 'RegistrationValidationBatchDetails',
          params: { batchId: item.id },
        }}},[_vm._v(_vm._s(item.filename))])]}},{key:"custom-actions",fn:function(ref){
        var item = ref.item;
return [_c('MDownloadButton',{attrs:{"id":("btn-download-list_" + (item.id)),"href":("/registrations/results/" + (item.id) + "/download")}}),_c('MDeleteButton',{staticClass:"ml-1",attrs:{"id":("btn-delete-list_" + (item.id)),"variant":"outline","size":"sm"},on:{"click":function($event){return _vm.handleDelete(item)}}})]}}])}),(_vm.showImport)?_c('RegistrationBatchImportModal',{attrs:{"id":"mod-import-reg-batch","show":_vm.showImport},on:{"update:show":function($event){_vm.showImport=$event},"imported":function () { return _vm.fetchData(); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }